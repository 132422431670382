import React from "react"
import Figure from "./figure"
import RichText from "./richText"
import Button from "./button"
import { Link } from "gatsby"
import { LinkTest, LinkFormat } from "../helpers/linkTest"

import styles from "./postSidebar.module.scss"

const PostSidebar = (props) => {
  return (
    <aside className={styles.postSidebar}>
      {props.logo &&
        <div className={styles.sidebarLogo}>
          <Figure {...props.logo} />
        </div>
      }
      {props.items &&
        <ul className={styles.sidebarList}>
          {props.items.map(item =>
            <li key={item._key}>
              <h4>{item.title}:</h4>
              <RichText blocks={item.content} />
            </li>
          )}
        </ul>
      }
      {props.main && props.main.link &&
        <Button link={props.main.link} type={props.main.type} label={props.main.label} />
      }
      {props.additional &&
        <ul className={styles.sidebarLinks}>
          {props.additional.map(resource =>
            <li key={resource._key}>
              <p>{resource.resourceTitle}</p>
              {LinkTest(resource.resourceLink.linkUrl) === "internal" ? (
                <Link to={LinkFormat(resource.resourceLink.linkUrl)}>{resource.resourceLink.linkLabel}</Link>
              ) : (
                <a href={resource.resourceLink.linkUrl}>{resource.resourceLink.linkLabel}</a>
              )}
            </li>
          )}
        </ul>
      }
    </aside>
  )
}

export default PostSidebar
