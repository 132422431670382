import React from "react"
import { Link } from "gatsby"
import SectionHeader from "./sectionHeader"
import Figure from "./figure"

import styles from "./logoList.module.scss"

const RelatedCaseStudies = (props) => {
  return (
    <section>
      <SectionHeader title="Related Case Studies"/>
      <ul className={styles.logoList}>
        {props.items.map(({node}) =>
          <li key={`${node._rawLogo._id}-related`}>
            <Link to={`/case-studies/${node.category.slug.current}/${node.slug.current}/`}>
              <Figure {...node._rawLogo} />
            </Link>
          </li>
        )}
      </ul>
    </section>
  )
}

export default RelatedCaseStudies
