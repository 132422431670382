import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import Post from "../../components/post"
import PostSidebar from "../../components/postSidebar"
import CTA from "../../components/cta"
import LogoList from "../../components/logoListCaseStudies"

export const query = graphql`
  query ($slug: String, $id: String, $cat: String) {
    sanityCaseStudy(slug: {current: {eq: $slug}}) {
      title
      slug {current}
      _rawHero
      _rawBody(resolveReferences: {maxDepth: 10})
      _rawLogo(resolveReferences: {maxDepth: 10})
      _rawSidebar
      _rawAdditionalResources
      _rawMainResource
      _rawCta
      _rawGlobalCta(resolveReferences: {maxDepth: 10})
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    allSanityCaseStudy(filter: {category: {slug: {current: {eq: $cat}}}, _id: {ne: $id}}, limit: 4) {
      edges {
        node {
          slug {current}
          category {
            slug { current }
          }
          _rawLogo(resolveReferences: {maxDepth: 10})
        }
      }
    }
  }
`

const CaseStudyTemplate = (props) => {
  const page = props.data.sanityCaseStudy
  const cta = page._rawGlobalCta ? page._rawGlobalCta.cta : page._rawCta
  const related = props.data.allSanityCaseStudy.edges
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description = page.seo && page.seo.description ? page.seo.description : null
  const image = page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null ? page.seo.openGraphImage.asset.url : null
  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero {...page._rawHero} theme="light" graph="angularLine" />
      <PostWrapper formLayout>
        <Post content={page._rawBody} />
        <PostSidebar
          items={page._rawSidebar}
          logo={page._rawLogo}
          main={page._rawMainResource}
          additional={page._rawAdditionalResources}
        />
      </PostWrapper>
      {cta && cta.links.length > 0 && <CTA {...cta} />}
      {related.length > 0 && <div style={{marginBottom: `32px`}}><LogoList items={related} /></div>}
    </Layout>
  )
}

export default CaseStudyTemplate
